import React, { useEffect, useState } from "react";
import "./contact-tel.css"
import Banner from "../../utils/banner/banner";
import Square from "../../../images/faculties/square.jpg"
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import Loader from "react-loader-spinner";
import { colors } from "@material-ui/core";

const ContactTel = () => {
    const [isLoading, setIsloading] = useState(false);
    const [contact, setContact] = useState([]);

    useEffect(async () => {
        await axios.get(`${serverLink}staff/website/telephone_contact`)
            .then(result => {
                if (result.data.length > 0) {
                    setContact(result.data);
                }
                setIsloading(false)
            })
    }, [])

    return isLoading === true ?
        <>
            <Loader />
        </>
        : <>
            <Banner image={Square} title="Telephone Contact" caption="" />

            <div className="layout-blocks-ucws-image_text container-fluid roundable block image-text image-right">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 min-height-fix">
                                <div className="rounded-box grey-back">
                                    <div className="container">
                                        <div className="row">
                                            {contact.length > 0 ? (
                                                contact.map((x, y) => (
                                                    <div className="col-md-6" key={y}>
                                                        <div className="contact-item">
                                                            <h3><u><b>{x.Office}</b></u></h3>
                                                            <p><b>Office Number: </b>{x.OfficeNumber}</p>
                                                            <p><b>Office Contact Number: </b>{x.PhoneNumber}</p>
                                                            <p><b>Office Email: </b>{x.OfficeEmailAddress}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="col-12">
                                                    <h4>No Contact added</h4>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.BazeFacultyListDetails
    };
};
export default connect(mapStateToProps, null)(ContactTel);
